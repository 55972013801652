import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { EmpresasComponent } from './pages/empresas/empresas.component';
import { CampanhasComponent } from './pages/campanhas/campanhas.component';
import { AcoesComponent } from './pages/acoes/acoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { LoadingComponent } from './components/loading/loading.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SlidesCampanhasComponent } from './pages/campanhas/slides-campanhas/slides-campanhas.component';
import { register } from 'swiper/element/bundle';
import { MensagemErrorComponent } from './components/mensagem-error/mensagem-error.component';
import { LineBreakPipe } from './pipes/line-break.pipe';
register();


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    EmpresasComponent,
    CampanhasComponent,
    AcoesComponent,
    ContatoComponent,
    HomeComponent,
    LoadingComponent,
    SlidesCampanhasComponent,
    MensagemErrorComponent,
    LineBreakPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskPipe,
    NgxMaskDirective,
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    HammerModule
  ],
  providers: [provideNgxMask()],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
