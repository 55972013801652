<div id="acoes">
  <div class="container">
    <h1>Ações</h1>
    <p class="mt-5">
        Acreditamos que
        pessoas
        se conectam
        com pessoas
        por isso estamos sempre em busca de ideias
        inovadoras para fortalecer laços e criar momentos
        de networking memoráveis e significativos, como
        encontros, reuniões mensais, coquetéis,
        workshops, podcasts, entre outros eventos.
    </p>

    <div class="mt-5">
      <ng-container *ngFor="let event of events; let idx = index">
        <div
          class="event mt-5 row align-items-center flex-row-reverse"
          [class.flex-row-reverse]="idx % 2 === 0"
        >
          <div class="col-lg-4">
            <img
              [src]="event.vl_event_links[0]"
              width="100%"
              data-bs-toggle="modal"
              data-bs-target="#modal-acao"
              (click)="selectPhotosEvent(event.id)"
              style="cursor: pointer"
            />

            <div class="small-images-container">
              <img
                *ngFor="let img of event.vl_event_links.slice(1, 4)"
                [src]="img"
                class="small-image"
                data-bs-toggle="modal"
                data-bs-target="#modal-acao"
                (click)="selectPhotosEvent(event.id)"
              />
            </div>
          </div>

          <div class="col-lg-8 mt-3 mt-lg-0">
            <h2>
              {{ event.nm_event.replace("-", "&hyphen;") }}
            </h2>
            <p [innerHTML]="event.ds_event | lineBreak"></p>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="modal fade" id="modal-acao" tabindex="-1" aria-labelledby="modal-acaoLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div>
            <div id="modalAcaoIndicators" class="carousel slide">
              <div class="carousel-indicators">
                <ng-container *ngFor="let photo of eventPhotosSelected; let idx = index">
                  <button type="button" data-bs-target="#modalAcaoIndicators" [attr.data-bs-slide-to]="idx"
                    [class.active]="idx === 0" aria-current="true" [attr.aria-label]="'Slide' + idx"></button>
                </ng-container>
              </div>
              <div class="carousel-inner">
                <ng-container *ngFor="let photo of eventPhotosSelected; let idx = index">
                  <div class="carousel-item active" [class.active]="idx === 0">
                    <img [src]="photo" class="d-block w-100" alt="...">
                  </div>
                </ng-container>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#modalAcaoIndicators"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#modalAcaoIndicators"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
