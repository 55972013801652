import { Component, OnInit, Renderer2 } from '@angular/core';
import { Partner } from 'src/app/interfaces/partner';
import { PublicService } from 'src/app/services/public.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  topPartners: Partner[] = [];

  constructor(
    private _publicService: PublicService,
    private _renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this._publicService.getTopPartners().subscribe(response => {
      this.topPartners = response;
    })

    this._loadScript();
  }

  private _loadScript(): void {
    const body = document.querySelector('body');
    const hasScript = document.querySelector('#respondi_src');

    if (!hasScript) {
      const script = this._renderer.createElement('script');

      this._renderer.setAttribute(script, 'id', 'static-elfsight');
      this._renderer.setAttribute(script, 'src', 'https://static.elfsight.com/platform/platform.js');
      this._renderer.setAttribute(script, 'data-use-service-core', '');
      this._renderer.setAttribute(script, 'defer', '');
  
      // Append script element to the component
      this._renderer.appendChild(body, script);
    }

  }

}
