import { Component } from '@angular/core';
import { Campaign } from 'src/app/interfaces/campaign';
import { PublicService } from 'src/app/services/public.service';

@Component({
  selector: 'app-campanhas',
  templateUrl: './campanhas.component.html',
  styleUrls: ['./campanhas.component.scss']
})
export class CampanhasComponent {
  campaigns: Campaign[] = [];

  constructor(
    private _publicService: PublicService
  ) {}

  ngOnInit(): void {
    this._publicService.getCampaigns().subscribe(response => {
      this.campaigns = response;
    });
  }
}
