<div id="footer">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-2">
        <a routerLink="/home">
          <img src="../../../assets/logo-white.svg" width="120px">
        </a>
      </div>

      <!-- <div class="col-lg-2">
        <a routerLink="/home">
          <img src="../../../assets/logo-white.svg" width="120px">
        </a>
        <div class="redes d-flex d-lg-none align-items-center justify-content-end">
          <a href="https://www.instagram.com/nucleo.elevese" target="_blank">
            <i class="bi bi-instagram"></i>
          </a>
        </div>
      </div> -->

      <div class="paginas col-lg-8 d-flex flex-column flex-lg-row justify-content-between mt-4 mt-lg-0">
        <a class="my-1 my-lg-0" routerLink="/home">Home</a>
        <a class="my-1 my-lg-0" routerLink="/empresas">Empresas</a>
        <a class="my-1 my-lg-0" routerLink="/campanhas">Campanhas</a>
        <!-- <a class="my-1 my-lg-0" routerLink="/acoes">Ações</a> -->
        <a class="my-1 my-lg-0" routerLink="/contato">Contato</a>
        <a class="my-1 my-lg-0" href="https://portal.nucleoelevese.com/cadastro">Cadastre-se</a>
        <a class="my-1 my-lg-0" [href]="_baseUrl">Login</a>
      </div>

      <div class="redes col-lg-2 d-flex justify-content-lg-end mt-4 mt-lg-0">
        <a href="https://www.instagram.com/nucleo.elevese" target="_blank">
          <i class="bi bi-instagram"></i>
        </a>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-8">
        <p class="text-center mt-4">NUCLEO ELEVE-SE LTDA &copy; 2023 - {{ yearCurrent }} | Todos os direitos reservados | Aurora Tech</p>
      </div>
    </div>
  </div>
</div>
