import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { City } from 'src/app/interfaces/city';
import { Company, CompanyRequest } from 'src/app/interfaces/company';
import { District } from 'src/app/interfaces/district';
import { OccupationArea } from 'src/app/interfaces/occupation-area';
import { PublicService } from 'src/app/services/public.service';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {

  cities: City[] = [];
  companies: Company[] = [];
  districts: District[] = [];
  filtersForm!: FormGroup;
  occupationAreas: OccupationArea[]= [];

  private _subscription = new Subscription();

  constructor(
    private _formBuilder: FormBuilder,
    private _publicService: PublicService
  ) { }

  ngOnInit(): void {
    this.filtersForm = this._formBuilder.group({
      district: [''],
      city: [{ value: '', disabled: true}],
      occupation_area_id: ['']
    });

    this._loadCompanies(this.filtersForm.value);
    this._loadOccupationAreas();
    this._loadDistricts();

    this._subscribeFiltersChanges();
  }

  private _subscribeFiltersChanges(): void {
    this._subscription.add(
      this.filtersForm.valueChanges.pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      ).subscribe(value => { 
        this._loadCompanies(value);
      })
    );

    this._subscription.add(
      this.filtersForm.get('district')?.valueChanges.subscribe(value => {
        this._loadCities(value);
      })
    );
  }

  private _loadCompanies(filters: CompanyRequest): void {
    this._publicService.getCompanies(filters).subscribe(response => {
      this.companies = response;
    });
  }

  private _loadOccupationAreas(): void {
    this._publicService.getOccupationAreas().subscribe(response => {
      this.occupationAreas = response;
    });
  }

  private _loadDistricts(): void {
    this._publicService.getDistricts().subscribe(response => {
      this.districts = response;
    });
  }

  private _loadCities(district: string): void {
    this._publicService.getCities(district).subscribe(response => {
      this.cities = response;
      this.filtersForm.get('city')?.enable();
    })
  }

}
