<div id="home">
  <div id="cabecalho-redes">
    <section id="cabecalho">
      <div class="container mx-auto">
        <div class="row">
          <div class="col-lg-7">
            <img src="../../../assets/logo-white.svg" width="60%">
  
            <p class="mt-5">
              Acreditamos que <span class="spotlight d-block fs-2">pessoas se conectam com pessoas</span>
            </p>
            <p class="mt-4">
              Por isso estamos sempre em busca de <span class="spotlight">ideias inovadoras</span> para fortalecer laços e
              criar momentos de networking memoráveis e significativos
            </p>
          </div>
        </div>
      </div>
    </section>
  
    <section id="redes">
      <div class="container">
        <h1>Nossas redes</h1>
        <p class="mt-4">Veja nossas últimas postagens em nossas redes sociais</p>
  
        <div class="elfsight-app-406a1da5-31c0-44db-ac2d-b3d6af32ae9c my-5" data-elfsight-app-lazy></div>
  
        <div class="d-flex justify-content-center">
          <a id="siga-instagram" href="https://www.instagram.com/nucleo.elevese" target="_blank">Siga e descubra como
            ganhar ainda mais pontos</a>
        </div>
      </div>
    </section>
  </div>

  <section id="parceiros-sobre">
    <div class="container py-5">
      <div id="sobre" class="row align-items-center">
        <div class="col-lg-6">
          <h2 class="mt-4">Quem somos</h2>
          <p class="text-justify">
            Somos um Núcleo de empresários que
            compartilham do mesmo propósito: fortalecer
            outras empresas e gerar bons negócios.
          </p>

          <p class="mt-3 text-justify">
            Com foco na área da construção civil, nosso
            objetivo é oferecer aos profissionais a certeza
            de que as empresas do Eleve-se possuem
            produtos de qualidade e um pós-venda
            excepcional, gerando segurança e confiança
            em suas recomendações.
          </p>
        </div>

        <div class="col-6 col-lg-3 d-flex mt-4 mt-lg-0">
          <img src="../../../assets/reuniao.jpg" width="200%">
        </div>
      </div>

      <div id="parceiros" class="mt-5 pt-5 row flex-row-reverse">
        <div class="col-lg-6">

          <h2 class="mt-4 text-justify">Parceiros de destaque</h2>
          <p class="text-justify">
            Além disso, contamos com um <span class="spotlight">programa de bonificação</span> para profissionais que
            especificam o Núcleo em suas
            indicações, para que eles possam acumular pontos e trocá-los futuramente por jantares e viagens.
          </p>

          <p class="text-justify mt-3">
            Confira nosso top 10 parceiros mais pontuados
          </p>
        </div>

        <div class="col-lg-6 mt-5 mt-lg-0">
          <div class="row justify-content-center">
            <div class="col-4 my-3 parceiro" *ngFor="let partner of topPartners">
              <img [src]="partner.vl_photo_url" width="150px" height="150px" class="profile-picture">
              <p class="text-center">{{ partner.nm_preferred }}</p>
              
              <!-- <div class="overlay p-lg-5">
                <p class="text-center">{{ partner.nm_preferred }}</p>
                <small class="text-center mt-2">{{ partner.nm_occupation_area }}</small>
      
                <div class="overlay p-lg-5">
                  <p class="text-center">{{ partner.nm_preferred }}</p>
                  <small class="text-center mt-2">{{ partner.nm_occupation_area }}</small>
        
                  <div class="mt-3 row align-items-center justify-content-around w-100">
                    <div class="col-4 col-lg mt-2 text-center" *ngFor="let contact of partner.contacts">
                      <ng-container [ngSwitch]="contact.nm_contact_type">
                        <a [href]="'mailto:' + contact.vl_contact" target="_blank" *ngSwitchCase="'E-mail'">
                          <img [src]="contact.vl_icon_url" width="32px">
                        </a>
                        <a [href]="'https://wa.me/55' + contact.vl_contact" target="_blank" *ngSwitchCase="'WhatsApp'">
                          <img [src]="contact.vl_icon_url" width="32px">
                        </a>
                        <a [href]="'tel:+55' + contact.vl_contact" target="_blank" *ngSwitchCase="'Telefone'">
                          <img [src]="contact.vl_icon_url" width="32px">
                        </a>
                        <a [href]="contact.vl_contact" target="_blank" *ngSwitchDefault>
                          <img [src]="contact.vl_icon_url" width="32px">
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div id="fundadoras" class="row align-items-center mt-5 pt-5">
        <div class="col-lg-6">
          <h2 class="mt-4">Fundadores</h2>

          <h4 class="mt-4 spotlight">Vinícius e Giselle</h4>
          <p class="text-justify">
            Vinicius e Giselle Paiva, especialistas em franchising, são os fundadores da rede de fast food <a href="https://www.instagram.com/mrfritzoficial" target="_blank" class="fundadoras">@Mr. Fritz</a>, 
            presente em shoppings renomados em mais de 10 estados brasileiros. 
            Com um olhar voltado para inovação, processos e expansão, seus projetos no Núcleo Eleve-se 
            estão transformando-o em uma franquia de sucesso nacional, promovendo relacionamentos estratégicos que beneficiam todos os parceiros, 
            lojistas e franqueados. Nossa missão é o crescimento em conjunto de todos que acreditam na prosperidade e em um servir ao próximo com excelência.
          </p>

          <h4 class="mt-4 spotlight">Lilian Vidal</h4>
          <p class="text-justify">
            Designer de Interiores, proprietária
            da <a href="https://www.instagram.com/vidalluxplanejados" target="_blank"
              class="fundadoras">@vidalluxplanejados</a>, mentora
            e uma empresária visionária, que
            acredita muito no poder da união e
            não mede esforços para crescer e
            levar todos a sua volta juntos.
          </p>
        </div>

        <div class="col-6 col-lg-3 d-flex justify-content-center justify-content-lg-end mt-4 mt-lg-0">
          <img src="../../../assets/vinicius_e_giselle.jpg" width="100%">
        </div>
        <div class="col-6 col-lg-3 d-flex justify-content-center justify-content-lg-end mt-4 mt-lg-0">
          <img src="../../../assets/lilian.jpg" width="100%">
        </div>
      </div>
    </div>
  </section>
</div>