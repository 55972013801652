<nav id="navbar" class="navbar navbar-expand-lg navbar-dark">
  <div class="container">
    <a class="navbar-brand" routerLink="/home">
      <img src="../../../assets/logo-white.svg" width="120px">
    </a>
    

    <div class="d-flex align-items-center">
      <a [href]="_baseUrl">
        <button class="portal-button d-block d-lg-none">Login</button>
      </a>

      <button class="navbar-toggler ms-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    
    <div class="collapse navbar-collapse flex-grow-0" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/empresas">Empresas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/campanhas">Campanhas</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/acoes">Ações</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" routerLink="/contato">Contato</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://portal.nucleoelevese.com/cadastro">Cadastre-se</a>
        </li>
      </ul>

      <a href="https://portal.nucleoelevese.com/">
        <button class="ms-4 portal-button d-none d-lg-block">Login</button>
      </a>
    </div>
  </div>
</nav>
