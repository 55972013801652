<swiper-container
  class="swiper-container"
  effect="coverflow"
  grab-cursor="true"
  centered-slides="true"
  initial-slide="2"
  prevent-clicks="true"
  slides-per-view="3"
  coverflow-effect-rotate="0"
  coverflow-effect-stretch="85"
  coverflow-effect-depth="350"
  coverflow-effect-modifier="1"
  coverflow-effect-slide-shadows="false"
  pagination="false"
  autoplay="true"
  loop="true"
  [autoplayDelay]="1000"
  [breakpoints]="breakpoints"
  >
  <swiper-slide *ngFor="let slide of slides">
    <img src="{{slide.vl_media_url}}" alt="">
    <div class="text">
      <h2>{{ slide.nm_campaign.replace("-", "&hyphen;") }} <span class="fs-5 spotlight">{{ slide.vl_points_required }} pontos</span></h2>

      <p [innerHTML]="(slide.ds_campaign.length > 500 ? slide.ds_campaign.slice(0, 500) + '...' : slide.ds_campaign)"></p>
    </div>
  </swiper-slide>


</swiper-container>
