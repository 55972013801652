import { Component, Input, OnInit } from '@angular/core';
import { Campaign } from 'src/app/interfaces/campaign';
import Swiper from 'swiper';

@Component({
  selector: 'app-slides-campanhas',
  templateUrl: './slides-campanhas.component.html',
  styleUrls: ['./slides-campanhas.component.scss']
})
export class SlidesCampanhasComponent implements OnInit {
  @Input() slides!: Campaign[];

  breakpoints = {
    320: { slidesPerView: 1 },
    480: { slidesPerView: 1 },
    768: { slidesPerView: 2 },
    1135: { slidesPerView: 2 },
    1400: { slidesPerView: 2 }
  };

  ngOnInit(): void {

  }

  constructor() {}
}
