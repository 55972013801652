<div id="empresas">
  <div class="container">
    <h1>Empresas</h1>
    <p class="mt-1">As empresas do Núcleo são
        <span class="spotlight">cuidadosamente selecionadas</span> para
        fazerem parte de um grupo <span class="spotlight">seleto e
        exclusivo</span>. Com foco nisso, criamos um
        selo de qualidade “Eleve-se”, para
        agregar valor nas negociações.</p>

    <div class="my-5" id="filtros">
      <form [formGroup]="filtersForm" class="row">
        <div class="col-lg-4">
          <label for="district" class="form-label">Estado</label>
          <select id="district" formControlName="district" class="form-select">
            <option value="">Todos os estados</option>
            <option [value]="district.vl_district" *ngFor="let district of districts">
              {{ district.vl_district }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label for="city" class="form-label">Cidade</label>
          <select id="city" formControlName="city" class="form-select">
            <option value="">Todas as cidades</option>
            <option [value]="city.vl_city" *ngFor="let city of cities">
              {{ city.vl_city }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label for="occupation_area_id" class="form-label">Área de atuação</label>
          <select id="occupation_area_id" formControlName="occupation_area_id" class="form-select">
            <option value="">Todas as áreas</option>
            <option [value]="occupationArea.id" *ngFor="let occupationArea of occupationAreas">
              {{ occupationArea.nm_occupation_area }}
            </option>
          </select>
        </div>
      </form>
    </div>

    <div class="row pt-5">
      <div class="empresa col-6 col-lg-3 mt-3 d-flex justify-content-center align-items-center" *ngFor="let company of companies">
        <img [src]="company.vl_photo_url" width="100%" height="auto">
        <div class="overlay p-lg-5">
          <p class="text-center">{{ company.nm_preferred }}</p>
          <small class="text-center mt-2">{{ company.nm_occupation_area }}</small>

          <div class="mt-3 row align-items-center justify-content-around w-100">
            <div class="col-4 col-lg mt-2 text-center" *ngFor="let contact of company.contacts">
              <ng-container [ngSwitch]="contact.nm_contact_type">
                <a [href]="'mailto:' + contact.vl_contact" target="_blank" *ngSwitchCase="'E-mail'">
                  <img [src]="contact.vl_icon_url" width="32px">
                </a>
                <a [href]="'https://wa.me/55' + contact.vl_contact" target="_blank" *ngSwitchCase="'WhatsApp'">
                  <img [src]="contact.vl_icon_url" width="32px">
                </a>
                <a [href]="'https://www.instagram.com/' + contact.vl_contact" target="_blank" *ngSwitchCase="'Instagram'">
                    <img [src]="contact.vl_icon_url" width="32px">
                </a>
                <a [href]="'https://www.facebook.com/' + contact.vl_contact" target="_blank" *ngSwitchCase="'Facebook'">
                    <img [src]="contact.vl_icon_url" width="32px">
                </a>
                <a [href]="'tel:+55' + contact.vl_contact" target="_blank" *ngSwitchCase="'Telefone'">
                  <img [src]="contact.vl_icon_url" width="32px">
                </a>
                <a [href]="contact.vl_contact" target="_blank" *ngSwitchDefault>
                  <img [src]="contact.vl_icon_url" width="32px">
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
