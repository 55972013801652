<div id="campanhas">
  <div class="container">
    <h1>Campanhas</h1>
    <p class="mt-5">
        Contamos com um
        programa de bonificação
        para os profissionais que especificam o Núcleo em suas
        indicações, para que eles possam acumular pontos e trocá-los
        futuramente por jantares e viagens.
    </p>

    <div class="mt-5">
      <ng-container *ngFor="let campaign of campaigns; let idx = index">
        <div class="campanha mt-5 row align-items-center flex-row-reverse" [class.flex-row-reverse]="idx % 2 === 0">
          <div class="col-lg-4">
            <img [src]="campaign.vl_media_url" width="100%">
          </div>
          <div class="col-lg-8 mt-3 mt-lg-0">
            <h2>{{ campaign.nm_campaign.replace("-", "&hyphen;") }} <span class="fs-5 spotlight">{{ campaign.vl_points_required }} pontos</span></h2>
            <p>{{ campaign.ds_campaign }}</p>
            <!-- 
            <ul class="mt-3">
              <li>Lorem ipsum</li>
              <li>Lorem ipsum</li>
              <li>Lorem ipsum</li>
            </ul> -->
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>