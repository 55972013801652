<div id="acoes">
  <div class="container">
    <h1>Ações</h1>
    <p class="mt-5">
        Acreditamos que
        pessoas
        se conectam
        com pessoas
        por isso estamos sempre em busca de ideias
        inovadoras para fortalecer laços e criar momentos
        de networking memoráveis e significativos, como
        encontros, reuniões mensais, coquetéis,
        workshops, podcasts, entre outros eventos.
    </p>

    <div class="mt-5">
      <ng-container *ngFor="let acao of [1, 2, 3, 4, 5]">
        <h2 class="mt-5">Ação {{ acao }}</h2>
        <div class="row acao">
          <div class="col-lg-4">
            <div class="d-flex justify-content-center">
              <button data-bs-toggle="modal" data-bs-target="#modal-acao">
                <img [src]="photos[0].img" width="auto" class="acao-banner">
              </button>
            </div>

            <div class="d-flex justify-content-between mt-3">
              <ng-container *ngFor="let photo of photos | slice:1:4; let idx = index">
                <button class="acao-previa" data-bs-toggle="modal" data-bs-target="#modal-acao">
                  <img [src]="photo.img" width="100%">
                </button>
              </ng-container>
            </div>
          </div>

          <div class="col-lg-8 mt-3 mt-lg-0">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta voluptates in fugit? Ullam eveniet sunt
              quia! Aspernatur excepturi autem nemo praesentium, deleniti ipsa eaque, aperiam quis dolore officia
              explicabo dignissimos.
            </p>
            <p class="my-3">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita provident architecto temporibus aperiam
              repudiandae inventore eum unde, possimus aut consequuntur, excepturi neque facere asperiores sed
              consectetur mollitia quasi vitae voluptatem!
            </p>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed nam non accusamus magnam. Dicta minus cumque
              quasi expedita nobis voluptatibus nam! Aliquid saepe corporis aut molestiae rerum repudiandae iure non!
            </p>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="modal fade" id="modal-acao" tabindex="-1" aria-labelledby="modal-acaoLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div>
            <div id="modalAcaoIndicators" class="carousel slide">
              <div class="carousel-indicators">
                <ng-container *ngFor="let photo of photos; let idx = index">
                  <button type="button" data-bs-target="#modalAcaoIndicators" [attr.data-bs-slide-to]="idx"
                    [class.active]="idx === 0" aria-current="true" [attr.aria-label]="'Slide' + idx"></button>
                </ng-container>
              </div>
              <div class="carousel-inner">
                <ng-container *ngFor="let photo of photos; let idx = index">
                  <div class="carousel-item active" [class.active]="idx === 0">
                    <img [src]="photo.img" class="d-block w-100" alt="...">
                  </div>
                </ng-container>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#modalAcaoIndicators"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#modalAcaoIndicators"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>