import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PublicService } from 'src/app/services/public.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  contactForm!: FormGroup;
  loading = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _publicService: PublicService
  ) { }

  ngOnInit(): void {
    this.contactForm = this._formBuilder.group({
      name: [null, Validators.required],
      phone: [null, [Validators.required, Validators.minLength(11)]],
      email: [null, [Validators.required, Validators.email]],
      district: [null, Validators.required],
      city: [null, Validators.required],
      subject_message: [null, Validators.required],
      message: [null, Validators.required]
    });
  }

  onSave(): void {
    if (this.contactForm.valid) {
      this.loading = true;

      this._publicService.sendNotification(this.contactForm.value).subscribe(_ => {
        this.loading = false;

        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Sua mensagem foi enviada com sucesso.'
        });

        this.contactForm.reset();
      }, error => {
        this.loading = false;

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.error.detail
        });
      });
    } else {
      Object.keys(this.contactForm.controls).forEach(controlName => {
        this.contactForm.controls[controlName].markAsTouched();
      });
    }
  }

}
