import { Component } from '@angular/core';

@Component({
  selector: 'app-acoes',
  templateUrl: './acoes.component.html',
  styleUrls: ['./acoes.component.scss']
})
export class AcoesComponent {

  currentModalPhotos: any[] = [];

  photos = [
    { img: "https://picsum.photos/800" },
    { img: "https://picsum.photos/400" },
    { img: "https://picsum.photos/500" },
    { img: "https://picsum.photos/600" }
  ];

}
