<app-loading *ngIf="loading"></app-loading>

<div id="contato">
  <div class="container">
    <h1>Contato</h1>

    <div class="mt-5">
      <div class="row">
        <div class="col-6">
          <p>
            Estamos aguardando ansiosos pelo seu contato, seja uma reclamação,
            dúvida, sugestão ou caso queira se tornar uma empresa parceira.
            Também estamos disponíveis se precisar de ajuda com a plataforma,
            se houver problemas na utilização ou se quiser resgatar seus pontos.
          </p>

          <p class="mt-5">Telefones</p>
          <p>(34) 99712-2629</p>

          <p class="mt-4">E-mail</p>
          <p>{{ 'nucleoeleve.se@gmail.com' }}</p>
        </div>

        <div class="col-6">
          <form [formGroup]="contactForm" (submit)="onSave()" class="row justify-content-end">
            <div class="col-6 mb-3">
              <label for="name" class="form-label">Nome</label>
              <input type="text" class="form-control" id="name" formControlName="name">
            </div>

            <div class="col-6 mb-3">
              <label for="phone" class="form-label">Telefone</label>
              <input type="text" class="form-control" id="phone" formControlName="phone"
                mask="(00) 0000-0000||(00) 00000-0000">
            </div>

            <div class="mb-3">
              <label for="email" class="form-label">E-mail</label>
              <input type="email" class="form-control" id="email" formControlName="email">
            </div>

            <div class="col-6 mb-3">
              <label for="district" class="form-label">Estado</label>
              <input type="text" class="form-control" id="district" formControlName="district">
            </div>

            <div class="col-6 mb-3">
              <label for="city" class="form-label">Cidade</label>
              <input type="text" class="form-control" id="city" formControlName="city">
            </div>

            <div class="mb-3">
              <label for="subject_message" class="form-label">Assunto</label>
              <input type="text" class="form-control" id="subject_message" formControlName="subject_message">
            </div>

            <div class="mb-3">
              <label for="message" class="form-label">Mensagem</label>
              <textarea class="form-control" id="message" rows="3" formControlName="message"></textarea>
            </div>

            <div class="d-flex justify-content-end">
              <button type="submit" class="btn btn-primary">Enviar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>