import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Campaign } from '../interfaces/campaign';
import { City } from '../interfaces/city';
import { Company, CompanyRequest } from '../interfaces/company';
import { ContactNotification } from '../interfaces/contact';
import { District } from '../interfaces/district';
import { OccupationArea } from '../interfaces/occupation-area';
import { Partner } from '../interfaces/partner';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  private _baseUrl = environment.apiUrl;

  constructor(
    private _http: HttpClient
  ) { }

  getTopPartners(): Observable<Partner[]> {
    const url = this._baseUrl + '/topPartner';
    return this._http.get<Partner[]>(url);
  }

  getDistricts(): Observable<District[]> {
    const url = this._baseUrl + '/district';
    return this._http.get<District[]>(url);
  }

  getCities(district: string): Observable<City[]> {
    const url = this._baseUrl + '/city';
    const params = new HttpParams().set('district', district);
    return this._http.get<City[]>(url, { params });
  }

  getOccupationAreas(): Observable<OccupationArea[]> {
    const url = this._baseUrl + '/occupationArea';
    return this._http.get<OccupationArea[]>(url);
  }

  getCompanies(filters: CompanyRequest): Observable<Company[]> {
    const url = this._baseUrl + '/company';
    let params = new HttpParams();

    if (filters.district) {
      params = params.set('district', filters.district);
    }

    if (filters.city) {
      params = params.set('city', filters.city);
    }
    
    if (filters.occupation_area_id) {
      params = params.set('occupation_area_id', filters.occupation_area_id);
    }

    return this._http.get<Company[]>(url, { params });
  }

  getCampaigns(): Observable<Campaign[]> {
    const url = this._baseUrl + '/campaigns';
    return this._http.get<Campaign[]>(url);
  }

  sendNotification(body: ContactNotification): Observable<void> {
    const url = this._baseUrl + '/notification';
    const params = new HttpParams()
      .set('name', body.name)
      .set('phone', body.phone)
      .set('email', body.email)
      .set('district', body.district)
      .set('city', body.city)
      .set('subject_message', body.subject_message)
      .set('message', body.message);

    return this._http.get<void>(url, { params });
  }

}
