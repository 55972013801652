<div id="campanhas">
  <div class="container">
    <h1>Campanhas</h1>
    <p class="mt-5">
      Contamos com um
      programa de bonificação
      para os profissionais que especificam o Núcleo em suas
      indicações, para que eles possam acumular pontos e trocá-los
      futuramente por jantares e viagens.
    </p>

    <div class="container-slide mt-5">
      <app-slides-campanhas [slides]="campaigns"></app-slides-campanhas>
    </div>
</div>
