import { Component, OnInit } from '@angular/core';
import { Event } from 'src/app/interfaces/event';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-acoes',
  templateUrl: './acoes.component.html',
  styleUrls: ['./acoes.component.scss']
})
export class AcoesComponent implements OnInit {

  events!: Event[];
  eventPhotosSelected!: string[];

  constructor(
    private _eventsService: EventsService
  ){}

  ngOnInit(): void {
    this._loadEvents();
  }

  selectPhotosEvent(index: number) {
    const photos = this.events.find((event) => event.id === index);

    this.eventPhotosSelected = photos?.vl_event_links as string[];
  }

  private _loadEvents() {
    this._eventsService.getEvents().subscribe((response) => {
      this.events = response;
      console.log('Eventos: ', this.events);
    })
  }

}
