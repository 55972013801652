import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Event } from '../interfaces/event';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private _baseUrl = environment.apiUrl + '/events';


  constructor(
    private _http: HttpClient
  ) { }

  getEvents(): Observable<Event[]> {
    const url = this._baseUrl;

    return this._http.get<Event[]>(url);
  }
}
